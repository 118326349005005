import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';

function LoginPage() {
  const [name, setName] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = generateId();
    
    try {
      // Guardar el nombre del usuario en Firestore
      await firebase.firestore().collection('users').doc(userId).set({
        name: name
      });
      
      // Redirigir a la página UserPage con el ID generado
      history.push(`/user/${userId}`);
    } catch (error) {
      console.error('Error al guardar el nombre del usuario:', error);
    }
  };

  const generateId = () => {
    return Math.random().toString(36).substring(7);
  };

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input 
          type="text" 
          id="name" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          required 
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default LoginPage;
