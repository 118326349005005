import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";

function UserPage({ match }) {
  const [stream, setStream] = useState(null);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState("");
  const [idToCall, setIdToCall] = useState("");
  const [myId, setMyId] = useState("");
  const [callData, setCallData] = useState(null);
  const myVideo = useRef();
  const remoteVideo = useRef();
  const peerRef = useRef();
  const dataChannel = useRef(null);

  useEffect(() => {
    const init = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setStream(mediaStream);
        if (myVideo.current) myVideo.current.srcObject = mediaStream;
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
      
      let storedId = localStorage.getItem("myId");
      if (!storedId) {
        storedId = generateId();
        localStorage.setItem("myId", storedId);
      }
      setMyId(storedId);
    };
    
    init();
  }, []);
  

  const generateId = () => {
    return Math.random().toString(36).substring(7);
  };

  const sendData = (data) => {
    if (dataChannel.current && dataChannel.current.readyState === "open") {
      dataChannel.current.send(JSON.stringify(data));
    }
  };

  const handleSignal = async (signal) => {
    try {
      if (signal.sdp) {
        await peerRef.current.setRemoteDescription(new RTCSessionDescription(signal.sdp));
        if (signal.sdp.type === "offer") {
          const answer = await peerRef.current.createAnswer();
          await peerRef.current.setLocalDescription(answer);
          sendData({ sdp: peerRef.current.localDescription });
        }
      } else if (signal.candidate) {
        await peerRef.current.addIceCandidate(new RTCIceCandidate(signal.candidate));
      }
    } catch (error) {
      console.error("Error handling signal:", error);
    }
  };

  const callUser = async () => {
    try {
      const peer = new RTCPeerConnection();
      peerRef.current = peer;
  
      if (stream) {
        stream.getTracks().forEach((track) => peer.addTrack(track, stream));
      }
  
      peer.onicecandidate = (event) => {
        if (event.candidate) {
          sendData({ candidate: event.candidate });
        }
      };
  
      peer.ontrack = (event) => {
        if (remoteVideo.current) {
          remoteVideo.current.srcObject = event.streams[0];
        }
      };
  
      dataChannel.current = peer.createDataChannel("signal");
  
      dataChannel.current.onmessage = (event) => {
        const signal = JSON.parse(event.data);
        handleSignal(signal);
      };
  
      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
      sendData({ sdp: peer.localDescription });
    } catch (error) {
      console.error("Error calling user:", error);
    }
  };
  

  const leaveCall = () => {
    setCallEnded(true);
    peerRef.current.close();
  };

  const handleCallRequest = async () => {
    console.log("Solicitud de llamada enviada a:", idToCall);
    try {
      const peer = new RTCPeerConnection();
      peerRef.current = peer;
  
      stream.getTracks().forEach((track) => peer.addTrack(track, stream));
  
      peer.onicecandidate = (event) => {
        if (event.candidate) {
          sendData({ candidate: event.candidate });
        }
      };
  
      dataChannel.current = peer.createDataChannel("signal");
  
      dataChannel.current.onmessage = (event) => {
        const signal = JSON.parse(event.data);
        handleSignal(signal);
      };
  
      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
  
      const callRef = firebase.database().ref("calls").push();
      callRef.set({
        callerId: myId,
        calleeId: idToCall,
        status: "pending",
        offer: offer,
      });
  
      const responseRef = firebase.database().ref(`responses/${callRef.key}`);
      responseRef.on("value", (snapshot) => {
        const response = snapshot.val();
        if (response && response.answer) {
          handleSignal({ sdp: response.answer });
        }
      });
      callUser();
    } catch (error) {
      console.error("Error calling user:", error);
    }
  };

  useEffect(() => {
    const callRef = firebase.database().ref("calls");
    callRef.on("child_added", (snapshot) => {
      const call = snapshot.val();
      console.log("Nueva llamada entrante:", call);
      if (call.calleeId === myId && call.status === "pending") {
        if (call.offer) {
          setCallData({ ...call, id: snapshot.key });
        } else {
          console.error("La oferta no está definida en la llamada entrante.");
        }
      }
    });
  }, [myId]);

  const handleAcceptCall = async () => {
    const callRef = firebase.database().ref(`calls/${callData.id}`);
    callRef.update({ status: "accepted" });
    setCallAccepted(true);
  
    try {
      const peer = new RTCPeerConnection();
      peerRef.current = peer;
  
      if (stream) {
        stream.getTracks().forEach((track) => peer.addTrack(track, stream));
      }
  
      peer.onicecandidate = (event) => {
        if (event.candidate) {
          sendData({ candidate: event.candidate });
        }
      };
  
      peer.ontrack = (event) => {
        if (remoteVideo.current) {
          remoteVideo.current.srcObject = event.streams[0];
        }
      };
  
      const responseRef = firebase.database().ref(`responses/${callData.id}`);
  
      if (callData.offer) {
        await peer.setRemoteDescription(new RTCSessionDescription(callData.offer));
        const answer = await peer.createAnswer();
        await peer.setLocalDescription(answer); // Establecer la descripción local primero
        sendData({ sdp: peer.localDescription });
  
        responseRef.set({ answer: peer.localDescription });
      } else {
        responseRef.once("value", async (snapshot) => {
          const response = snapshot.val();
          if (response && response.offer) {
            await peer.setRemoteDescription(new RTCSessionDescription(response.offer));
            const answer = await peer.createAnswer();
            await peer.setLocalDescription(answer); // Establecer la descripción local primero
            sendData({ sdp: peer.localDescription });
            responseRef.set({ answer: peer.localDescription });
          } else {
            console.error("La oferta no está definida en la base de datos.");
          }
        });
      }
    } catch (error) {
      console.error("Error accepting call:", error);
    }
  };
  
  
  useEffect(() => {
    if (callAccepted && callData && callData.offer) {
      const initPeer = async () => {
        try {
          const peer = new RTCPeerConnection();
          peerRef.current = peer;
  
          if (stream) {
            stream.getTracks().forEach((track) => peer.addTrack(track, stream));
          }
  
          peer.onicecandidate = (event) => {
            if (event.candidate) {
              sendData({ candidate: event.candidate });
            }
          };
          peer.ontrack = (event) => {
            if (remoteVideo.current) {
              remoteVideo.current.srcObject = event.streams[0];
              if (event.streams[0].active) {
                // MediaStream is active, you can proceed with your logic here
              }
            }
          };
          
  
          await peer.setRemoteDescription(new RTCSessionDescription(callData.offer));
          const answer = await peer.createAnswer();
          await peer.setLocalDescription(answer);
          sendData({ sdp: peer.localDescription });
  
          const responseRef = firebase.database().ref(`responses/${callData.id}`);
          responseRef.set({ answer: peer.localDescription });
        } catch (error) {
          console.error("Error accepting call:", error);
        }
      };
  
      initPeer();
    }
  }, [callAccepted, callEnded, callData]);
  

 
  useEffect(() => {
    if (remoteVideo.current && remoteVideo.current.srcObject) {
      const remoteStream = remoteVideo.current.srcObject;
      if (remoteStream.getTracks().length > 0) {
        // Solo intenta acceder a active si hay pistas en el MediaStream
        remoteStream.getTracks().forEach((track) => {
          track.enabled = true; // Activa las pistas del MediaStream
        });
      }
    }
  }, [remoteVideo.current]);
  
  
  return (
    <div>
      <h1>User Page</h1>
      <p>User ID: {match.params.userId}</p>
      <div>
        {stream && <video playsInline muted ref={myVideo} autoPlay style={{ width: "300px" }} />}
      </div>
      <div>
        {callAccepted && (
          <video playsInline muted ref={remoteVideo} autoPlay style={{ width: "300px" }}>
            Your Video
          </video>
        )}
      </div>
      <div>
        <TextField
          id="filled-basic"
          label="Name"
          variant="filled"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        <TextField
          id="filled-basic"
          label="ID to call"
          variant="filled"
          value={idToCall}
          onChange={(e) => setIdToCall(e.target.value)}
        />
        <div>
          {!callAccepted && !callEnded ? (
            <Button variant="contained" color="primary" onClick={handleCallRequest}>
              Call
            </Button>
          ) : null}
          {callAccepted && !callEnded ? (
            <Button variant="contained" color="secondary" onClick={leaveCall}>
              End Call
            </Button>
          ) : null}
          {!callAccepted && !callEnded && callData ? (
            <Button variant="contained" style={{ backgroundColor: "green" }} onClick={handleAcceptCall}>
              Accept Call
            </Button>
          ) : null}
        </div>
      </div>
      <h1>Your ID: {myId}</h1>
    </div>
  );
}  

export default UserPage;
