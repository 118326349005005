// ReceiverPage.js
import React, { useRef, useEffect } from "react";

function ReceiverPage({ callerStream }) {
    const callerVideo = useRef();
  
    useEffect(() => {
      if (callerStream) {
        callerVideo.current.srcObject = callerStream;
      }
    }, [callerStream]);
  
    return (
      <div>
        <h2>Caller's Video</h2>
        <video playsInline ref={callerVideo} autoPlay style={{ width: "300px" }} />
      </div>
    );
  }
  

export default ReceiverPage;
