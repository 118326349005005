// App.js
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import firebase from "firebase/app";
import "firebase/database";
import ReceiverPage from "./ReceiverPage";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBFDp-0SThHBcLLCN-t-X4MJaWGUnuGoVc",
  authDomain: "tryteckapp.firebaseapp.com",
  databaseURL: "https://tryteckapp-default-rtdb.firebaseio.com",
  projectId: "tryteckapp",
  storageBucket: "tryteckapp.appspot.com",
  messagingSenderId: "587749752296",
  appId: "1:587749752296:web:81c819eee966508141f85d",
  measurementId: "G-1RQWHQ2EES"
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();

function App() {
  const [stream, setStream] = useState(null);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState("");
  const [idToCall, setIdToCall] = useState("");
  const [myId, setMyId] = useState("");
  const [callData, setCallData] = useState(null);
  const myVideo = useRef();
  const userVideo = useRef();
  const peerRef = useRef();
  const dataChannel = useRef(null);

  // Inicialización de la aplicación
  useEffect(() => {
    const init = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setStream(mediaStream);
        if (myVideo.current) myVideo.current.srcObject = mediaStream;
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
      
      let storedId = localStorage.getItem("myId");
      if (!storedId) {
        storedId = generateId();
        localStorage.setItem("myId", storedId);
      }
      setMyId(storedId);
    };
    
    init();
  }, []);

  // Generar ID aleatorio
  const generateId = () => {
    return Math.random().toString(36).substring(7);
  };

  // Función para enviar datos a través del canal de datos
  const sendData = (data) => {
    if (dataChannel.current && dataChannel.current.readyState === "open") {
      dataChannel.current.send(JSON.stringify(data));
    }
  };

  // Manejar la señal recibida
  const handleSignal = async (signal) => {
    try {
      if (signal.sdp) {
        await peerRef.current.setRemoteDescription(new RTCSessionDescription(signal.sdp));
        if (signal.sdp.type === "offer") {
          const answer = await peerRef.current.createAnswer();
          await peerRef.current.setLocalDescription(answer);
          sendData({ sdp: peerRef.current.localDescription });
        }
      } else if (signal.candidate) {
        await peerRef.current.addIceCandidate(new RTCIceCandidate(signal.candidate));
      }
    } catch (error) {
      console.error("Error handling signal:", error);
    }
  };

  // Llamar a un usuario
  const callUser = async () => {
    try {
      const peer = new RTCPeerConnection();
      peerRef.current = peer;

      stream.getTracks().forEach((track) => peer.addTrack(track, stream));

      peer.onicecandidate = (event) => {
        if (event.candidate) {
          sendData({ candidate: event.candidate });
        }
      };

      dataChannel.current = peer.createDataChannel("signal");

      dataChannel.current.onmessage = (event) => {
        const signal = JSON.parse(event.data);
        handleSignal(signal);
      };

      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
      sendData({ sdp: peer.localDescription });
    } catch (error) {
      console.error("Error calling user:", error);
    }
  };

  // Terminar llamada
  const leaveCall = () => {
    setCallEnded(true);
    peerRef.current.close();
  };

  // Manejar solicitud de llamada
  const handleCallRequest = async () => {
    console.log("Solicitud de llamada enviada a:", idToCall);
    const callRef = database.ref("calls").push();
    callRef.set({
      callerId: myId,
      calleeId: idToCall,
      status: "pending"
    });
  };

  // Manejar aceptación de llamada
  const handleAcceptCall = async () => {
    const callRef = database.ref(`calls/${callData.id}`);
    callRef.update({ status: "accepted" });
    setCallAccepted(true);
  };
  
  // Observar llamadas entrantes
  useEffect(() => {
    const callRef = database.ref("calls");
    callRef.on("child_added", (snapshot) => {
      const call = snapshot.val();
      if (call.calleeId === myId && call.status === "pending") {
        setCallData({ ...call, id: snapshot.key });
      }
    });
  }, [myId]);

  // Establecer video local al aceptar la llamada
  useEffect(() => {
    if (callAccepted && !callEnded) {
      if (userVideo.current) userVideo.current.srcObject = stream;
    }
  }, [callAccepted, callEnded, stream]);

  return (
    <>
      <h1 style={{ textAlign: "center", color: "#fff" }}>Zoomish</h1>
      <div className="container">
        <div className="video-container">
          <div className="video">
            {stream && (
              <video playsInline muted ref={myVideo} autoPlay style={{ width: "300px" }} />
            )}
          </div>
          <div className="video">
            {callData && callData.callerId && (
              <ReceiverPage
                myId={myId}
                stream={stream}
                callerId={callData.callerId}
                callAccepted={callAccepted}
                setCallAccepted={setCallAccepted}
                userVideoRef={userVideo} // Pasar userVideo como prop
              />
            )}
          </div>
        </div>
        <div className="myId">
          <TextField
            id="filled-basic"
            label="Name"
            variant="filled"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="filled-basic"
            label="ID to call"
            variant="filled"
            value={idToCall}
            onChange={(e) => setIdToCall(e.target.value)}
          />
          <div className="call-button">
            {!callAccepted && !callEnded ? (
              <Button variant="contained" color="primary" onClick={handleCallRequest}>
                Call
              </Button>
            ) : null}
            {callAccepted && !callEnded ? (
              <Button variant="contained" color="secondary" onClick={leaveCall}>
                End Call
              </Button>
            ) : null}
            {!callAccepted && !callEnded && callData ? (
              <Button variant="contained" style={{ backgroundColor: "green" }} onClick={handleAcceptCall}>
                Accept Call
              </Button>
            ) : null}
          </div>
        </div>
        <h1 style={{ textAlign: "center", color: "#fff" }}>Your ID: {myId}</h1>
      </div>
    </>
  );
}

export default App;
